import React from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  Nav,
  NavItem,
} from 'reactstrap'
import { StaticQuery, graphql, Link } from 'gatsby'
import LogoFooter from '../../assets/img/logo_footer.svg'
import LogoIso from '../../assets/img/iso.png'
import LogoAlink from '../../assets/img/alink-logo.png'
import menu from '../../menu'
import certificazione from '../../assets/pdf/2023_06_13_QE_091_20_9001_rev01_S2_EC.pdf'
import quality from '../../assets/pdf/MOD-520-Politica_qualita.pdf'

const SocialLink = ({ url, kind }) =>
  url ? (
    <>
      <Button
        className="btn-neutral text-white"
        color="default"
        size="sm"
        href={url}
        id={kind}
        target="_blank"
        rel="noopener"
      >
        <i className={`icon-${kind}`} />
      </Button>
      <UncontrolledTooltip delay={0} target={kind}>
        Follow us
      </UncontrolledTooltip>
    </>
  ) : (
    ''
  )

SocialLink.propTypes = {
  url: PropTypes.string,
  kind: PropTypes.string.isRequired,
}

SocialLink.defaultProps = {
  url: undefined,
}

const Footer = () => (
  <StaticQuery
    query={graphql`
      query companyFooterQuery {
        datoCmsCompany {
          email
          city
          address
          address2
          name
          phone
          zip
          vatNumber
          twitter
          facebook
          instagram
          linkedin
        }
      }
    `}
    render={data => {
      const company = data.datoCmsCompany
      return (
        <footer id="footer" className="section mx-auto">
          <Container>
            <Row>
              <Col md="3">
                <img alt="Materia3 logo" src={LogoFooter} className="mb-2" />
                <p className="text-white">
                  <small className="block">
                    © {new Date().getFullYear()} {company.name} <br />
                    <span className="text-muted">
                      Sede legale ed operativa:
                    </span>
                    <br /> {company.address} {company.zip} {company.city}
                    <br />
                    Tel: {company.phone} · email:{' '}
                    <a href={`mailto:${company.email}`} className="text-danger">
                      {company.email}
                    </a>
                    <br />
                    P.iva: {company.vatNumber} ·{' '}
                    <Link to="/privacy" className="text-danger">
                      Privacy
                    </Link>
                  </small>
                </p>
                <div className="btn-wrapper socials mb-3">
                  {['twitter', 'facebook', 'instagram', 'linkedin'].map(
                    kind => (
                      <SocialLink key={kind} url={company[kind]} kind={kind} />
                    )
                  )}
                </div>
              </Col>
              <Col md="3" className="d-none d-md-block">
                <h4 className="text-muted">Azienda</h4>
                <Nav vertical>
                  {menu.map(({ name, link }) => {
                    if (link === '/') return <></>
                    return (
                      <NavItem>
                        <Link to={link} className="text-white">
                          {name}
                        </Link>
                      </NavItem>
                    )
                  })}
                </Nav>
              </Col>
              <Col md="3" className="mb-4">
                <h4 className="mb-1 text-muted">Certificazioni</h4>
                <p>
                  <a
                    className="text-white"
                    target="noopener _blank"
                    href={quality}
                  >
                    Politica per la qualità
                  </a>
                </p>
                <a target="noopener _blank" href={certificazione}>
                  <img
                    alt="QAID ISO"
                    src={LogoIso}
                    className="mt-1"
                    width="85"
                  />
                </a>
              </Col>
              <Col md="3">
                <h4 className="mb-1 text-muted">Rete di imprese</h4>
                <p>
                  <a
                    className="text-white"
                    target="noopener _blank"
                    href="https://www.ambientalink.it"
                  >
                    Ambientalink
                  </a>
                </p>
                <a target="noopener _blank" href="https://www.ambientalink.it">
                  <img
                    alt="A-Link Logo"
                    src={LogoAlink}
                    className="mt-1"
                    width="150"
                  />
                </a>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <div className="credits border-top text-center mt-2 pt-3">
                  <a href="https://www.extendi.it" className="d-block">
                    <p>Made by Extendi</p>
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      )
    }}
  />
)

export default Footer
