const menu = [
  {
    name: 'Home',
    link: '/',
  },
  {
    name: 'Profilo',
    link: '/profilo',
  },
  {
    name: 'Lavori svolti',
    link: '/progetti',
  },
  {
    name: 'Servizi',
    link: '/servizi',
  },
  {
    name: 'Strumentazione',
    link: '/strumentazione',
  },
  {
    name: 'Contatti',
    link: '/contatti',
  },
];

export default menu;
