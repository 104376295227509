/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql, Link } from 'gatsby';
import CookieConsent from 'react-cookie-consent';
import SEO from './SEO';
import ComponentsNavbar from './Navbar/ComponentsNavbar';
import Footer from './Footer/Footer';
import '../assets/css/materia-icons.css';
import '../assets/scss/main.scss';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={() => (
      <>
        <SEO />
        <div id="wrapper">
          <ComponentsNavbar />
          {children}
          <Footer />
          <CookieConsent disableStyles buttonClasses="btn btn-secondary btn-sm" containerClasses="alert col-lg-12 mb-0" buttonText="Ho capito" contentClasses="mb-2 mb-sm-0">
            Questo sito web utilizza i cookie per migliorare la navigazione.
            Utilizzando il sito si intende accettata la nostra
            {' '}
            <Link to="/cookie-policy">Cookie Policy</Link>
.
          </CookieConsent>
        </div>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
