import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  Row,
  Col,
} from 'reactstrap';

import Logo from '../../assets/img/logo.svg';

import menu from '../../menu';

const NavLink = ({ to, children, exactPath }) => (
  <Link
    to={to}
    className="nav-link"
    activeStyle={{ borderBottom: '3px solid' }}
    partiallyActive={!exactPath}
  >
    {children}
  </Link>
);

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  exactPath: PropTypes.bool,
};

NavLink.defaultProps = {
  exactPath: false,
};

class ComponentsNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
    };

    this.onCollapseExiting = this.onCollapseExiting.bind(this);
    this.onCollapseExited = this.onCollapseExited.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  onCollapseExiting() {
    this.setState({
      collapseOut: 'collapsing-out',
    });
  }

  onCollapseExited() {
    this.setState({
      collapseOut: '',
    });
  }

  toggleCollapse() {
    this.setState(state => ({
      collapseOpen: !state.collapseOpen,
    }));
  }

  render() {
    return (
      <Navbar expand="lg">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand to="/" tag={Link}>
              <img alt="Materia3" src={Logo} />
            </NavbarBrand>
            <button
              type="button"
              aria-expanded={this.state.collapseOpen}
              aria-label="Menu"
              className="navbar-toggler navbar-toggler"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse
            className={`justify-content-end ${this.state.collapseOut}`}
            navbar
            isOpen={this.state.collapseOpen}
            onExiting={this.onCollapseExiting}
            onExited={this.onCollapseExited}
          >
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <img alt="Materia3" src={Logo} />
                </Col>
                <Col className="collapse-close text-right" xs="6">
                  <button
                    type="button"
                    aria-expanded={this.state.collapseOpen}
                    aria-label="Menu"
                    className="navbar-toggler"
                    onClick={this.toggleCollapse}
                  >
                    <i className="icon-close" />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav navbar>
              {menu.map(({ name, link }) => (
                <NavItem>
                  <NavLink to={link} exactPath={link === '/'}>
                    {name}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default ComponentsNavbar;
